import { FC } from "react";
import { ContentfulComponentType } from "../types";
import { AppLevelsAndDiscountsCards } from "./AppLevelsAndDiscountsCards/AppLevelsAndDiscountsCards";
import { ClaimsPageEmailBlock } from "./ClaimsPageEmailBlock/ClaimsPageEmailBlock";
import { HelpYouHelpBlock } from "./HelpYouHelpBlock/HelpYouHelpBlock";
import { LassieInMedia } from "./LassieInMedia/LassieInMedia";
import { TassfondenBlock } from "./TassfondenBlock/TassfondenBlock";
import AppStoreIcons from "./AppStoreIcons/AppStoreIcons";
import Benefits from "./Benefits/Benefits";
import InformationIcons from "./InformationIcons";
import PremadeHero from "./PremadeHero";
import ReviewsSection from "./ReviewsSection/ReviewsSection";
import TassfondenAddToCartButton from "./TassfondenAddToCartButton/TassfondenAddToCartButton";
import InsuranceDocuments from "./InsuranceDocuments";

export enum ComponentTypes {
  Trustpilot = "Trustpilot",
  InsuranceDocuments = "Insurance documents",
  //Deprecated:
  TrustPilot = "trustPilot",
  AppStoreIcons = "appStoreIcons",
  AppLevelsAndDiscountsCards = "appLevelsAndDiscountsCards",
  ClaimsPageEmailBlock = "claimsPageEmailBlock",
  HelpYouHelpBlock = "helpYouHelpBlock",
  InformationIcons = "informationIcons",
  LassieInMedia = "lassieInMedia",
  TassfondenBlock = "tassfondenBlock",
  TassfondenAddToCartButton = "tassfondenAddToCartButton",
  PremadeHero = "premadeHero",
  Benefits = "benefits",
}

export const ComponentMap: Record<
  string,
  ContentfulComponentType<any> | FC<any>
> = {
  [ComponentTypes.Trustpilot]: ReviewsSection,
  [ComponentTypes.InsuranceDocuments]: InsuranceDocuments,
  [ComponentTypes.TrustPilot]: ReviewsSection,
  [ComponentTypes.ClaimsPageEmailBlock]: ClaimsPageEmailBlock,
  [ComponentTypes.AppLevelsAndDiscountsCards]: AppLevelsAndDiscountsCards,
  [ComponentTypes.TassfondenBlock]: TassfondenBlock,
  [ComponentTypes.InformationIcons]: ({ en, de }) => (
    <InformationIcons de={de} en={en} />
  ),
  [ComponentTypes.HelpYouHelpBlock]: HelpYouHelpBlock,
  [ComponentTypes.LassieInMedia]: LassieInMedia,
  [ComponentTypes.Benefits]: Benefits,
  [ComponentTypes.PremadeHero]: PremadeHero,
  [ComponentTypes.AppStoreIcons]: AppStoreIcons,
};

export const RichTextMap: Record<
  string,
  ContentfulComponentType<any> | FC<any>
> = {
  [ComponentTypes.AppStoreIcons]: AppStoreIcons,
  [ComponentTypes.TassfondenAddToCartButton]: TassfondenAddToCartButton,
};
