import React from "react";
import { useRouter } from "next/router";
import { gql } from "@apollo/client";

import { Button } from "../../../ui-kit/Button";
import Colors from "../../../ui-kit/colors";
import StoreIcon from "../../StoreIcon";

import { ContentfulComponentType } from "../types";
import { C2A } from "../../../../__generated__/C2A";

import styles from "./ContentfulC2A.module.css";
import IconButton from "components-shared/IconButton";
import { useWebTranslation } from "translations";
import { Typography } from "ui-kit/Typography";
import Gutter from "ui-kit/Gutter";
import { FlexRow } from "ui-kit/Layout/Flex";
import { CalendlyWidget } from "../ContentfulComponent/Calendly/Widget";
import { getMeetingBookingUrl } from "utils/site";
import { CalendlyPopUp } from "../ContentfulComponent/Calendly/PopUp";
import { MessageButton } from "./MessageButton";

enum C2ATypeEnum {
  StandardButton = "Standard Button",
  DownloadAppButtons = "Download App Buttons",
  SupportButtons = "Support buttons",
  CalendlyWidget = "Calendly Widget (inline)",
  CalendlyPopup = "Calendly Popup",
}

enum C2AColorEnum {
  Blue = "Blue",
  Lightblue = "Lightblue",
  Green = "Green",
}

export const getButtonColors = (
  color: C2A["color"]
): { color: keyof typeof Colors; textColor: keyof typeof Colors } => {
  if (color === C2AColorEnum.Blue) {
    return { color: "blue300", textColor: "white" };
  }

  if (color === C2AColorEnum.Lightblue) {
    return { color: "blue100", textColor: "blue300" };
  }

  if (color === C2AColorEnum.Green) {
    return { color: "green200", textColor: "white" };
  }

  return { color: "blue300", textColor: "white" };
};

export const ContentfulC2A: ContentfulComponentType<
  C2A & {
    iconButtonclassName?: string;
  }
> = ({ type, title, info, destination, color, style, iconButtonclassName }) => {
  const { t } = useWebTranslation();
  const router = useRouter();

  const onPress = () => {
    if (destination?.__typename === "Page") {
      const slug = destination.slug ?? "";
      router.push(slug);
    }

    if (destination?.__typename === "Url") {
      const url = destination.url ?? "";
      const hasFileExtension = url.match(/\.[a-z]{1,4}$/i);

      if (hasFileExtension) {
        const a = document.createElement("a");
        a.href = url;
        a.download = url.split("/").pop() ?? "";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } else {
        router.push(url);
      }
    }
  };

  const buttonColors = getButtonColors(color);

  const meetingBookingUrl = getMeetingBookingUrl();

  return (
    <>
      {type === C2ATypeEnum.StandardButton &&
        (style ? (
          <IconButton
            title={title!}
            backgroundColor="white"
            onPress={onPress}
            color={buttonColors.color}
            icon="chevron-right"
            style={{ paddingLeft: 0, paddingRight: 0 }}
            className={iconButtonclassName}
          />
        ) : (
          <FlexRow className={styles.container} alignItems="center">
            <Button
              variant="custom"
              title={title!}
              onPress={onPress}
              color={buttonColors.color}
              textColor={buttonColors.textColor}
              className={styles.button}
            />
            {info && (
              <>
                <Gutter size={2} horizontal />
                <Typography color="grey400">{info}</Typography>
              </>
            )}
          </FlexRow>
        ))}
      {type === C2ATypeEnum.DownloadAppButtons && <StoreIcon />}
      {type === C2ATypeEnum.SupportButtons && (
        <div className={styles.supportButtons}>
          <div className={styles.container}>
            <MessageButton />
          </div>
          {meetingBookingUrl ? (
            <div className={styles.container}>
              <CalendlyPopUp
                meetingBookingUrl={meetingBookingUrl}
                buttonStyles={styles.button}
                buttonTitle={t("c2a.schedule_call")}
              />
            </div>
          ) : null}
        </div>
      )}
      {type === C2ATypeEnum.CalendlyWidget &&
        destination?.__typename === "Url" &&
        destination.url && <CalendlyWidget url={destination.url} />}

      {type === C2ATypeEnum.CalendlyPopup && meetingBookingUrl ? (
        <CalendlyPopUp
          meetingBookingUrl={meetingBookingUrl}
          buttonStyles={styles.button}
          buttonTitle={t("c2a.schedule_call")}
        />
      ) : null}
    </>
  );
};

ContentfulC2A.fragment = gql`
  fragment C2A on ComponentC2A {
    type
    title
    info
    destination {
      ... on Url {
        url
      }
      ... on Page {
        slug
      }
    }
    color
    style
    sys {
      id
    }
  }
`;
