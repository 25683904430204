import React, { FC, ReactNode } from "react";
import classNames from "classnames";
import styles from "./index.module.css";

type Props = {
  mobile?: boolean;
  tablet?: boolean;
  desktop?: boolean;
  children?: ReactNode;
};

const ShowBlockOnMedia: FC<Props> = ({
  mobile = true,
  tablet = true,
  desktop = true,
  children,
}) => {
  return (
    <>
      <div
        className={classNames(styles.base, {
          [styles.mobile]: mobile,
          [styles.tablet]: tablet,
          [styles.desktop]: desktop,
        })}
      >
        {children}
      </div>
    </>
  );
};

export default ShowBlockOnMedia;
