import Image from "next/image";
import { useWebTranslation } from "translations";
import Icon from "ui-kit/Icon";
import { Typography } from "ui-kit/Typography";
import { ListStyleEnum } from ".";
import { getIcon } from "../icons";
import Colors from "ui-kit/colors";
import styles from "./List.module.css";

const ListStyle = ({
  listStyle,
  icon,
  index,
}: {
  listStyle?: ListStyleEnum;
  icon?: string | null;
  index: number;
}) => {
  const { t } = useWebTranslation();

  if (listStyle === ListStyleEnum.Bullet) {
    return (
      <Typography variant="body1" color="navy300" className={styles.listStyle}>
        •
      </Typography>
    );
  }

  if (listStyle === ListStyleEnum.Check) {
    return (
      <Icon
        name="checkmark_small"
        size={18}
        color={Colors.green200}
        className={styles.listStyleCheck}
      />
    );
  }

  if (listStyle === ListStyleEnum.Number) {
    return (
      <div className={styles.listStyleNumber}>
        <Typography variant="body3">
          <strong>{index + 1}</strong>
        </Typography>
      </div>
    );
  }

  if (listStyle === ListStyleEnum.Icon) {
    const { src, altKey } = getIcon(icon);
    return (
      <Image
        src={src}
        height={40}
        width={40}
        alt={t(altKey)}
        className={styles.listStyle}
      />
    );
  }

  return <div />;
};

export default ListStyle;
