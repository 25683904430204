import Script from "next/script";

const ELEMENT_ID = "calendlyWidget";

export const CalendlyWidget = ({ url }: { url: string }) => {
  return (
    <>
      <div style={{ width: "100%", height: 1000 }} id={ELEMENT_ID}></div>
      <Script
        strategy="afterInteractive"
        src="https://assets.calendly.com/assets/external/widget.js"
        onLoad={() => {
          if (window.Calendly) {
            window.Calendly.initInlineWidget({
              url,
              parentElement: document.getElementById(ELEMENT_ID),
            });
          }
        }}
      />
    </>
  );
};
