const icons = {
  Paw: { src: "/images/benefits/paw.svg", altKey: "benefits.benefit.pet" },
  Clap: {
    src: "/images/benefits/clap.svg",
    altKey: "benefits.benefit.friendly",
  },
  Flash: {
    src: "/images/benefits/flash.svg",
    altKey: "benefits.benefit.claim",
  },
  Phone: {
    src: "/images/benefits/phone.svg",
    altKey: "benefits.benefit.app",
  },
  Percent: {
    src: "/images/benefits/percent.svg",
    altKey: "benefits.benefit.discount",
  },
  Knowledge: {
    src: "/images/benefits/knowledge.svg",
    altKey: "benefits.benefit.knowledge",
  },
  Star: {
    src: "/images/benefits/star.svg",
    altKey: "benefits.benefit.star",
  },
  Scale: {
    src: "/images/benefits/scale.svg",
    altKey: "benefits.benefit.switch",
  },
  Cat: {
    src: "/images/benefits/cat.svg",
    altKey: "benefits.benefit.cat",
  },
  Dog: {
    src: "/images/benefits/dog.svg",
    altKey: "benefits.benefit.dog",
  },
  Headset: {
    src: "/images/benefits/headset.svg",
    altKey: "benefits.benefit.headset",
  },
  Firstaid: {
    src: "/images/benefits/firstaid.svg",
    altKey: "benefits.benefit.firstaid",
  },
  Calendar: {
    src: "/images/benefits/calendar.svg",
    altKey: "benefits.benefit.calendar",
  },
  Trophy: {
    src: "/images/benefits/trophy.svg",
    altKey: "benefits.benefit.trophy",
  },
  Document: {
    src: "/images/benefits/document.svg",
    altKey: "benefits.benefit.document",
  },
};

export const getIcon = (icon?: string | null) =>
  icons[icon as keyof typeof icons] ?? icons.Paw;
