import React, { FC, ReactNode, useState } from "react";
import styles from "./CompactPackagesSection.module.css";
import { useWebTranslation } from "../../../../translations";
import { PackageConfig } from "../types";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import InsurancePackage from "components/Signup/InsurancePackages/InsurancePackage";
import Tabs from "ui-kit/Tabs/Tabs";
import Gutter from "ui-kit/Gutter";
import Overlay from "components/Signup/Overlay";
import MobileFullPackages from "../MobileFullPackages/MobileFullPackages";
import InsurancePackagesTable from "../InsurancePackagesTable/InsurancePackagesTable";
import ShowBlockOnMedia from "ui-kit/ShowBlockOnMedia";
import { AmountToPay } from "utils/insurance/getAmountToPay";

const SwiperController = ({
  packageConfig,
  selectedIndex,
  onChange,
}: {
  packageConfig: PackageConfig;
  selectedIndex: number;
  onChange: CompactPackagesSectionProps["onChange"];
}) => {
  const swiper = useSwiper();
  const { t } = useWebTranslation();

  return (
    <Tabs
      items={[]}
      tabs={packageConfig.packages.map((pack, index) => ({
        value: pack.title,
        label: t(pack.title),
      }))}
      onChangeTabs={(_, index) => {
        swiper.slideTo(index);
        onChange && onChange({ selectedIndex: index });
      }}
      index={selectedIndex}
    />
  );
};

type CompactPackagesSectionProps = {
  config: PackageConfig;
  onChange?: ({ selectedIndex }: { selectedIndex: number }) => void;
  packagePrices?: AmountToPay[];
  numberOfHighlightItems?: number;
  numberOfVisibleItems?: number;
  initialIndex?: number;
};

const CompactPackagesSection: FC<CompactPackagesSectionProps> = ({
  config,
  onChange,
  packagePrices = [],
  numberOfHighlightItems,
  numberOfVisibleItems,
  initialIndex = 0,
}) => {
  const { t } = useWebTranslation();

  const [selectedIndex, setSelectedIndex] = useState(initialIndex);
  const [overlayDisplay, setOverlayDisplay] = useState<{
    title: string;
    content: ReactNode;
    full?: boolean;
  } | null>(null);

  const compareOverlay = {
    title: t("quote_preview.compare_packages_overlay"),
    content: (
      <>
        <ShowBlockOnMedia desktop={false} tablet={true} mobile={true}>
          <MobileFullPackages
            config={config}
            defaultSelectedIndex={selectedIndex}
            expanded
          />
        </ShowBlockOnMedia>
        <ShowBlockOnMedia desktop={true} tablet={false} mobile={false}>
          <InsurancePackagesTable config={config} />
        </ShowBlockOnMedia>
      </>
    ),
    full: true,
  };

  return (
    <>
      <ShowBlockOnMedia mobile={true} tablet={true} desktop={false}>
        <Swiper
          slidesPerView={1.25}
          spaceBetween={20}
          initialSlide={selectedIndex}
          onSlideChange={(e) => {
            setSelectedIndex(e.activeIndex);

            onChange &&
              onChange({
                selectedIndex: e.activeIndex,
              });
          }}
          centeredSlides
          className={styles.swiper}
        >
          <div slot="container-start" style={{ margin: "0 16px" }}>
            <SwiperController
              packageConfig={config}
              selectedIndex={selectedIndex}
              onChange={({ selectedIndex }) => {
                setSelectedIndex(selectedIndex);

                onChange &&
                  onChange({
                    selectedIndex: selectedIndex,
                  });
              }}
            />
            <Gutter size={3} />
          </div>
          {config.packages.map((pack, index) => (
            <SwiperSlide key={pack.title} style={{ height: "auto" }}>
              <>
                <InsurancePackage
                  packageConfig={config}
                  pack={pack}
                  amountToPay={packagePrices[index]}
                  active={index === selectedIndex}
                  onCompare={() => setOverlayDisplay(compareOverlay)}
                  style={{ height: "100%" }}
                  numberOfHighlightItems={numberOfHighlightItems}
                  numberOfVisibleItems={numberOfVisibleItems}
                />
              </>
            </SwiperSlide>
          ))}
        </Swiper>
      </ShowBlockOnMedia>
      <ShowBlockOnMedia desktop={true} mobile={false} tablet={false}>
        <div
          className={styles.gridContainer}
          style={{
            gridTemplateColumns: `repeat(${
              config.packages.length % 2 === 0 ? "2" : "3"
            }, 1fr)`,
          }}
        >
          {config.packages.map((pack, index) => (
            <InsurancePackage
              key={pack.title}
              packageConfig={config}
              pack={pack}
              amountToPay={packagePrices[index]}
              onCompare={() => setOverlayDisplay(compareOverlay)}
              style={{
                margin: "auto",
                marginTop: pack.popular ? 0 : 24,
                minWidth: 260,
                width: "100%",
                maxWidth: 400,
                height: "calc(100% - 24px)",
              }}
              numberOfHighlightItems={numberOfHighlightItems}
              numberOfVisibleItems={numberOfVisibleItems}
            />
          ))}
        </div>
      </ShowBlockOnMedia>
      <Overlay
        display={overlayDisplay}
        onRemove={() => setOverlayDisplay(null)}
      />
    </>
  );
};

export default CompactPackagesSection;
